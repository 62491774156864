exports.components = {
  "component---src-pages-about-1-js": () => import("./../../../src/pages/about-1.js" /* webpackChunkName: "component---src-pages-about-1-js" */),
  "component---src-pages-about-2-js": () => import("./../../../src/pages/about-2.js" /* webpackChunkName: "component---src-pages-about-2-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-artificial-intelligence-js": () => import("./../../../src/pages/artificial-intelligence.js" /* webpackChunkName: "component---src-pages-artificial-intelligence-js" */),
  "component---src-pages-author-js": () => import("./../../../src/pages/author.js" /* webpackChunkName: "component---src-pages-author-js" */),
  "component---src-pages-b-2-b-scaling-js": () => import("./../../../src/pages/b2b-scaling.js" /* webpackChunkName: "component---src-pages-b-2-b-scaling-js" */),
  "component---src-pages-bg-check-js": () => import("./../../../src/pages/bg-check.js" /* webpackChunkName: "component---src-pages-bg-check-js" */),
  "component---src-pages-bigdata-analytics-js": () => import("./../../../src/pages/bigdata-analytics.js" /* webpackChunkName: "component---src-pages-bigdata-analytics-js" */),
  "component---src-pages-blog-1-js": () => import("./../../../src/pages/blog-1.js" /* webpackChunkName: "component---src-pages-blog-1-js" */),
  "component---src-pages-blog-2-js": () => import("./../../../src/pages/blog-2.js" /* webpackChunkName: "component---src-pages-blog-2-js" */),
  "component---src-pages-blog-3-js": () => import("./../../../src/pages/blog-3.js" /* webpackChunkName: "component---src-pages-blog-3-js" */),
  "component---src-pages-blog-4-js": () => import("./../../../src/pages/blog-4.js" /* webpackChunkName: "component---src-pages-blog-4-js" */),
  "component---src-pages-blog-5-js": () => import("./../../../src/pages/blog-5.js" /* webpackChunkName: "component---src-pages-blog-5-js" */),
  "component---src-pages-blog-6-js": () => import("./../../../src/pages/blog-6.js" /* webpackChunkName: "component---src-pages-blog-6-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-brands-st-js": () => import("./../../../src/pages/brands-st.js" /* webpackChunkName: "component---src-pages-brands-st-js" */),
  "component---src-pages-business-intelligence-js": () => import("./../../../src/pages/business-intelligence.js" /* webpackChunkName: "component---src-pages-business-intelligence-js" */),
  "component---src-pages-candidate-screening-js": () => import("./../../../src/pages/candidate-screening.js" /* webpackChunkName: "component---src-pages-candidate-screening-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-client-and-partners-js": () => import("./../../../src/pages/client-and-partners.js" /* webpackChunkName: "component---src-pages-client-and-partners-js" */),
  "component---src-pages-cloud-services-js": () => import("./../../../src/pages/cloud-services.js" /* webpackChunkName: "component---src-pages-cloud-services-js" */),
  "component---src-pages-cmr-js": () => import("./../../../src/pages/cmr.js" /* webpackChunkName: "component---src-pages-cmr-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-content-marketing-js": () => import("./../../../src/pages/content-marketing.js" /* webpackChunkName: "component---src-pages-content-marketing-js" */),
  "component---src-pages-custom-software-development-js": () => import("./../../../src/pages/custom-software-development.js" /* webpackChunkName: "component---src-pages-custom-software-development-js" */),
  "component---src-pages-data-analylist-js": () => import("./../../../src/pages/data-analylist.js" /* webpackChunkName: "component---src-pages-data-analylist-js" */),
  "component---src-pages-devops-services-js": () => import("./../../../src/pages/Devops-services.js" /* webpackChunkName: "component---src-pages-devops-services-js" */),
  "component---src-pages-digital-agency-js": () => import("./../../../src/pages/digital-agency.js" /* webpackChunkName: "component---src-pages-digital-agency-js" */),
  "component---src-pages-digital-agency-portfolio-js": () => import("./../../../src/pages/digital-agency-portfolio.js" /* webpackChunkName: "component---src-pages-digital-agency-portfolio-js" */),
  "component---src-pages-digital-marketings-js": () => import("./../../../src/pages/digital-marketings.js" /* webpackChunkName: "component---src-pages-digital-marketings-js" */),
  "component---src-pages-ecom-management-js": () => import("./../../../src/pages/ecom-management.js" /* webpackChunkName: "component---src-pages-ecom-management-js" */),
  "component---src-pages-employer-branding-js": () => import("./../../../src/pages/employer-branding.js" /* webpackChunkName: "component---src-pages-employer-branding-js" */),
  "component---src-pages-erp-scaling-js": () => import("./../../../src/pages/erp-scaling.js" /* webpackChunkName: "component---src-pages-erp-scaling-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-feature-details-js": () => import("./../../../src/pages/feature-details.js" /* webpackChunkName: "component---src-pages-feature-details-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-hiring-js": () => import("./../../../src/pages/hiring.js" /* webpackChunkName: "component---src-pages-hiring-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-hosting-js": () => import("./../../../src/pages/hosting.js" /* webpackChunkName: "component---src-pages-hosting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-influencer-marketing-js": () => import("./../../../src/pages/influencer-marketing.js" /* webpackChunkName: "component---src-pages-influencer-marketing-js" */),
  "component---src-pages-interview-coordination-js": () => import("./../../../src/pages/interview-coordination.js" /* webpackChunkName: "component---src-pages-interview-coordination-js" */),
  "component---src-pages-iot-js": () => import("./../../../src/pages/iot.js" /* webpackChunkName: "component---src-pages-iot-js" */),
  "component---src-pages-it-consulting-js": () => import("./../../../src/pages/it-consulting.js" /* webpackChunkName: "component---src-pages-it-consulting-js" */),
  "component---src-pages-it-professionals-js": () => import("./../../../src/pages/it-professionals.js" /* webpackChunkName: "component---src-pages-it-professionals-js" */),
  "component---src-pages-it-services-js": () => import("./../../../src/pages/it-services.js" /* webpackChunkName: "component---src-pages-it-services-js" */),
  "component---src-pages-it-support-maintainance-js": () => import("./../../../src/pages/it-support-maintainance.js" /* webpackChunkName: "component---src-pages-it-support-maintainance-js" */),
  "component---src-pages-job-market-analysis-js": () => import("./../../../src/pages/job-market-analysis.js" /* webpackChunkName: "component---src-pages-job-market-analysis-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logistic-management-js": () => import("./../../../src/pages/logistic-management.js" /* webpackChunkName: "component---src-pages-logistic-management-js" */),
  "component---src-pages-machine-learning-2-js": () => import("./../../../src/pages/machine-learning-2.js" /* webpackChunkName: "component---src-pages-machine-learning-2-js" */),
  "component---src-pages-machine-learning-js": () => import("./../../../src/pages/machine-learning.js" /* webpackChunkName: "component---src-pages-machine-learning-js" */),
  "component---src-pages-marketing-js": () => import("./../../../src/pages/marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */),
  "component---src-pages-marketings-automation-js": () => import("./../../../src/pages/marketings-automation.js" /* webpackChunkName: "component---src-pages-marketings-automation-js" */),
  "component---src-pages-mobile-app-development-js": () => import("./../../../src/pages/mobile-app-development.js" /* webpackChunkName: "component---src-pages-mobile-app-development-js" */),
  "component---src-pages-mobile-commerce-solution-js": () => import("./../../../src/pages/mobile-commerce-solution.js" /* webpackChunkName: "component---src-pages-mobile-commerce-solution-js" */),
  "component---src-pages-network-setup-js": () => import("./../../../src/pages/network-setup.js" /* webpackChunkName: "component---src-pages-network-setup-js" */),
  "component---src-pages-omi-channel-js": () => import("./../../../src/pages/omi-channel.js" /* webpackChunkName: "component---src-pages-omi-channel-js" */),
  "component---src-pages-other-services-js": () => import("./../../../src/pages/other-services.js" /* webpackChunkName: "component---src-pages-other-services-js" */),
  "component---src-pages-our-clients-js": () => import("./../../../src/pages/our-clients.js" /* webpackChunkName: "component---src-pages-our-clients-js" */),
  "component---src-pages-our-collabration-js": () => import("./../../../src/pages/our-collabration.js" /* webpackChunkName: "component---src-pages-our-collabration-js" */),
  "component---src-pages-pagenotfound-js": () => import("./../../../src/pages/pagenotfound.js" /* webpackChunkName: "component---src-pages-pagenotfound-js" */),
  "component---src-pages-pay-per-click-js": () => import("./../../../src/pages/pay-per-click.js" /* webpackChunkName: "component---src-pages-pay-per-click-js" */),
  "component---src-pages-pc-repair-js": () => import("./../../../src/pages/pc-repair.js" /* webpackChunkName: "component---src-pages-pc-repair-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-details-js": () => import("./../../../src/pages/product-details.js" /* webpackChunkName: "component---src-pages-product-details-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-project-details-1-js": () => import("./../../../src/pages/project-details1.js" /* webpackChunkName: "component---src-pages-project-details-1-js" */),
  "component---src-pages-project-details-2-js": () => import("./../../../src/pages/project-details2.js" /* webpackChunkName: "component---src-pages-project-details-2-js" */),
  "component---src-pages-project-details-js": () => import("./../../../src/pages/project-details.js" /* webpackChunkName: "component---src-pages-project-details-js" */),
  "component---src-pages-projects-1-js": () => import("./../../../src/pages/projects-1.js" /* webpackChunkName: "component---src-pages-projects-1-js" */),
  "component---src-pages-projects-2-js": () => import("./../../../src/pages/projects-2.js" /* webpackChunkName: "component---src-pages-projects-2-js" */),
  "component---src-pages-quality-assurance-testing-js": () => import("./../../../src/pages/quality-assurance-testing.js" /* webpackChunkName: "component---src-pages-quality-assurance-testing-js" */),
  "component---src-pages-rec-sta-dev-js": () => import("./../../../src/pages/rec-sta-dev.js" /* webpackChunkName: "component---src-pages-rec-sta-dev-js" */),
  "component---src-pages-sass-product-development-js": () => import("./../../../src/pages/sass-product-development.js" /* webpackChunkName: "component---src-pages-sass-product-development-js" */),
  "component---src-pages-search-engine-js": () => import("./../../../src/pages/search-engine.js" /* webpackChunkName: "component---src-pages-search-engine-js" */),
  "component---src-pages-seo-content-js": () => import("./../../../src/pages/seo-content.js" /* webpackChunkName: "component---src-pages-seo-content-js" */),
  "component---src-pages-service-details-js": () => import("./../../../src/pages/service-details.js" /* webpackChunkName: "component---src-pages-service-details-js" */),
  "component---src-pages-services-1-js": () => import("./../../../src/pages/services-1.js" /* webpackChunkName: "component---src-pages-services-1-js" */),
  "component---src-pages-services-2-js": () => import("./../../../src/pages/services-2.js" /* webpackChunkName: "component---src-pages-services-2-js" */),
  "component---src-pages-services-3-js": () => import("./../../../src/pages/services-3.js" /* webpackChunkName: "component---src-pages-services-3-js" */),
  "component---src-pages-services-4-js": () => import("./../../../src/pages/services-4.js" /* webpackChunkName: "component---src-pages-services-4-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-servicesworking-js": () => import("./../../../src/pages/servicesworking.js" /* webpackChunkName: "component---src-pages-servicesworking-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-skill-assement-js": () => import("./../../../src/pages/skill-assement.js" /* webpackChunkName: "component---src-pages-skill-assement-js" */),
  "component---src-pages-software-engineers-js": () => import("./../../../src/pages/software-engineers.js" /* webpackChunkName: "component---src-pages-software-engineers-js" */),
  "component---src-pages-sourcing-candidate-js": () => import("./../../../src/pages/sourcing-candidate.js" /* webpackChunkName: "component---src-pages-sourcing-candidate-js" */),
  "component---src-pages-talent-acq-js": () => import("./../../../src/pages/talent-acq.js" /* webpackChunkName: "component---src-pages-talent-acq-js" */),
  "component---src-pages-talent-pool-development-js": () => import("./../../../src/pages/talent-pool-development.js" /* webpackChunkName: "component---src-pages-talent-pool-development-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-virtual-services-js": () => import("./../../../src/pages/virtual-services.js" /* webpackChunkName: "component---src-pages-virtual-services-js" */),
  "component---src-pages-vitual-event-management-js": () => import("./../../../src/pages/vitual-event-management.js" /* webpackChunkName: "component---src-pages-vitual-event-management-js" */),
  "component---src-pages-web-developments-js": () => import("./../../../src/pages/web-developments.js" /* webpackChunkName: "component---src-pages-web-developments-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

